.topbar__notification {
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    box-shadow: none;
    padding: 6px 10px 0 5px;
    background-color: transparent;
  
    &:hover,
    &:active {
      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
}

.unread-notification-popup-number {
    background: #ff3030 !important;
    height: 13.5px;
    width: 13.5px;
    border-radius: 20px;
    position: absolute;
    right: 2px;
    top: 12px;
    font-size: 8px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    color: #fff;
    text-align: center;
}

.notification-popup-center-heading {
    color: #1e232d;
    font-size: 14px;
    line-height: 25px;
    font-weight: bolder;
    margin-top: 15px;
    margin-bottom: 20px;
    height: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-popup-container{
    padding-left: 0px !important;
    height:290px;
    width: 100%;
}

.notification-popup-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    padding-bottom: 10px;
    cursor: pointer;
}

.notification-popup-item-picture {
    width:50px;
    height:50px;
    margin-right: 12px;
    > img {
        border-radius: 2px;
        height: inherit; 
        width: inherit; 
        object-fit: cover;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    }
}

.notification-popup-item-info {
    display: flex;
    flex-direction: column;

    > div {
        color: #757575;
        font-size: 10px;
        font-weight: 400;
        word-wrap: break-word;
        white-space: initial;
        height: 14px;
        width: 100%;
        padding-top: 1px;
    }
    > p {
        line-height: 20px;
        color: rgb(0, 0, 0);
        font-size: 13px;
        font-weight: bolder;
        padding: 0;
        margin: 0;
        word-wrap: break-word;
        white-space: initial;
        height: 40px;
        width: 100%;
    }
}


.notification-popup-view-all {
    color: #1DA8EB;
    font-size: 12px;
    line-height: 25px;
    font-weight: bolder;
    padding: 0 12px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: rgb(0, 0, 0);
      }
}

.notification-center-heading {
    color: #000 !important;
    font-size: 18px;
    line-height: 25px;
    font-weight: bolder;
    padding: 10px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
}

.notification-center-container {
    width: 100%;
    height: auto;
    min-height: 600px;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notification-center-item-list {
    width: 100%;
    height: auto;
    // min-height: 600px;
    padding: 0px !important;
}

.notification-center-item-container {
    border-bottom: 1px solid rgb(224, 224, 224);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    cursor: pointer;
}

.notification-center-unread-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: auto;
    margin-right: 10px;
    > .unread-notification{
        width: 9px;
        height: 9px;
        border-radius: 9px;
        background-color: #1DA8EB;
        color: transparent;
    }

}

.notification-center-item-picture {
    width:50px;
    height:50px;
    margin-right: 12px;
    > img {
        border-radius: 2px;
        height: inherit; 
        width: inherit; 
        object-fit: cover;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    }
}

.notification-center-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
        color: #757575;
        font-size: 10px;
        font-weight: 400;
        word-wrap: break-word;
        white-space: initial;
        padding-top: 1px;
    }
    > p {
        line-height: 20px;
        color: rgb(0, 0, 0);
        font-size: 13px;
        font-weight: bolder;
        padding: 0;
        margin: 0;
        word-wrap: break-word;
        white-space: initial;
    }
}

.notification-center-border-top {
    border-top: 1px solid rgb(224, 224, 224);
}

.notification-center-border-bottom {
    border-top: unset !important;
}

.notification-center-pagination-section {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 410px) {
        padding-bottom: 15px;
        padding-top: 20px;
    }
    div {
        div {
            @media (max-width: 480px) {
                display: flex;
                flex-wrap: wrap-reverse;
                justify-content: center;
            }
        }
    } 
}

.notification-loader-align-center {
    justify-content: center !important;
}

.no-new-notification {
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-new-notification-popup {
    height:290px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.old-notification-gap {
    margin-right: 12px;
}

.icon-btn-refresh {
    margin-left: 5px !important;
    padding: 1px !important;
    height: 20px !important;
    padding-bottom: 8px !important;
    margin-top: 4px !important;
}

.refresh-notifications {
    > svg {
        font-size: 16px !important;
    }
}

.refresh-notifications-circular {
    > svg {
        font-size: 16px !important;
        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin 2s linear infinite;
    }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}