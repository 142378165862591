// .MuiSelect-select-940 {
//     padding-right: 26px !important;
// }
// .MuiSelect-select-284 {
//     padding-right: 30px !important;
// }

@media screen and (max-width: 480px) {
    #responsive-dialog-title{
        width: 270px !important;
    }
  }

.button-filter-packages{
    // width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    margin-left: 14px !important;
}

.login-text-img{
    text-align: center !important;
}

.mobile-stepper-color{
    background-color:  rgb(255, 30, 89) !important;
}
.mobile-stepper-color>div{
    background-color:  rgb(255, 30, 89) !important;
}
.business-selector-associate-top-display{
    display: flex  !important;
    align-content: center  !important;
    justify-content: space-between  !important;
}

.table--bordered {
    border-top: 1px solid #eaeaea;
}

.modal-all-members{
    position: absolute !important;
    top: 120px !important;
    right: 20px !important;
    width: 200px;
    height: 130px;
    outline: none;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    border-radius: 2px;
    background-color: #fff;
}

.chip-top-bar-search {
    // font-size: 12px !important;
    // height: 20px !important;
    margin-top: 7px !important;
    margin-right: 7px !important;
    background-color: #027AFF !important;
    color: #fff !important;
    font-size: 14px !important;
    transition: 2s !important;
    transition-timing-function: ease-in !important;
}

.top-searchBar-message {
    width: 35%;
    font-size: 10px;
    margin-top: 7px;
    text-align: right;
    color: darkgray;
}

.space-between-associate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @media screen and (max-width: 500px) {
        display: block;
    }
}

.space-between-associate-inherit {
    display: inherit !important;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
}