.delete-account-text-para1 {
  margin-bottom: 1.5rem;
}

.delete-account-text-para2 {
  margin-bottom: 1.5rem;
  color: #737a92;
  font-weight: 700;
}

.delete-account-text-bullet-point {
  margin-left: 1rem;
}

.account-settings-card-header {
  padding: 20px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  height: 60px;
}

.account-settings-card {
  height: 450px;
}

.flex-container-account-settings {
  padding: 20px;
  display: flex;
  height: 390px;
  flex-direction: column;
  justify-content: space-between;
}

.popup-delete-button {
  margin-right: 20px;
  @media (max-width: 515px) {
    margin: 0;
    margin-bottom: 15px;
  }
}

.popup-buttons-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    justify-content: center;
  }
}
