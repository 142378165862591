@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

$landing-accent-color: #027AFF;
$landing-gradient: linear-gradient(228deg, #027AFF, #027AFF);
$landing-gradient-hover: linear-gradient(228deg, darken(rgba(127, 244, 222, 0.95), 5%), darken(rgba(56, 199, 225, 1), 5%));

.landing {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background: #ffffff;
  position: absolute;
  z-index: 0;
  overflow-x: hidden;

  .container {
    transition: 0.3s;
  }

  @media (min-width: 576px) {

    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {

    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {

    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {

    .container {
      max-width: 1140px;
    }
  }
}

.landing__btn {
  background-color: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(89, 181, 210, 0.31);
  color: $landing-accent-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 23px;
  height: 46px;
  padding: 10px 30px;
  transition: 0.3s;
  white-space: nowrap;
  border: none;

  &:hover {
    color: $landing-accent-color;
    background: darken(#ffffff, 5%);
    box-shadow: 0 5px 15px 0 rgba(89, 181, 210, 0.5);
  }

  &.landing__btn--header {
    display: inline-block;
    margin: 0 15px 20px;
    align-items: center;
    width: auto;
    padding: 16px 30px;
    font-family: 'Poppins', sans-serif;
  }
}

.landing__btn--gradient {
  background: $landing-gradient;
  color: #ffffff;

  &:hover {
    background: $landing-gradient-hover;
    color: #ffffff;
  }
}

//Menu

.landing__menu {
  position: absolute;
  width: 100%;
}

.landing__menu-wrap {
  width: 100%;
  display: flex;
  padding-top: 62px;
}

.landing__menu-logo {
  margin-top: -6px;
  height: 42px;
}

.landing__menu-nav {
  margin-left: auto;

  button {
    position: relative;
    background: transparent;
    border: none;
    color: $landing-accent-color;
    padding: 0;
    margin-right: 60px;
    font-size: 14px;
    transition: 0.3s;
    cursor: pointer;
    display: none;
    font-family: 'Poppins', sans-serif;

    &:hover {
      color: darken($landing-accent-color, 15%);
    }

    @media screen and (min-width: 992px) {
      display: inline-block;
    }
  }
}

.landing__menu-nav-new {
  position: absolute;
  background: #f06a8e;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  right: -6px;
  display: block;
  top: -3px;
}

//Header

.landing__header {
  padding-top: 245px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.landing .landing__header-title {
  max-width: 680px;
  line-height: normal;
  margin: auto;
  color: $landing-accent-color;
  margin-bottom: 20px;
}

.landing__header-img {
  margin-top: 80px;
  max-width: 1000px;
}

.landing .landing__header-subhead {
  color: $landing-accent-color;
  font-size: 16px;
  line-height: 1.8;
  max-width: 500px;
  margin-bottom: 30px;
  margin-right: auto;
  margin-left: auto;
}

.landing__header-subhead-update {
  text-decoration: underline;
  font-weight: 700;
  color: $landing-accent-color;

  &:hover {
    color: $landing-accent-color;
  }
}

//Section

.landing__section {
  margin-bottom: 180px;
  position: relative;
}

.landing__section-background {
  position: absolute;
  bottom: -50px;
  right: 0;
  z-index: -1;
  width: 95%;
  height: 1830px;
  transition: height 0.3s;

  @media screen and (min-width: 576px) {
    width: 85%;
  }

  @media screen and (min-width: 768px) {
    width: 75%;
    height: 1200px;
    bottom: -400px;
  }

  @media screen and (min-width: 992px) {
    width: 65%;
    bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    width: 55%;
  }

  &.landing__section-background--technologies {
    transform: scale(-1, 1);
    left: 0;
    right: auto;
    height: 950px;
    top: 1850px;
    bottom: auto;
    width: 95%;

    @media screen and (min-width: 480px) {
      width: 85%;
    }

    @media screen and (min-width: 768px) {
      width: 75%;
      top: 850px;
    }

    @media screen and (min-width: 992px) {
      width: 60%;
    }

    @media screen and (min-width: 1200px) {
      width: 45%;
    }
  }
}

.landing .landing__section-title {
  max-width: 680px;
  text-align: center;
  color: $landing-accent-color;
  margin: auto auto 50px;
  line-height: 40px;
  position: relative;
  font-weight: 500;
}

.landing .landing__additional-title {
  max-width: 680px;
  text-align: center;
  color: $landing-accent-color;
  margin: 70px auto 50px auto;
}

.landing__section-title-img {
  position: absolute;
  height: 7px;
  width: 85px;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;

  @media screen and (min-width: 425px) {
    left: auto;
    top: auto;
    transform: none;
  }
}

//Technology

.landing__technology {
  text-align: center;
  max-width: 168px;
  margin: auto;
}

.landing__technology-img-wrap {
  width: 100%;
  height: 156px;
  display: flex;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.landing__technology-img {
  width: 100%;
  margin: auto;
}

.landing .landing__technology-title {
  font-size: 18px;
  line-height: 1.44;
  color: #666666;
  margin: 0;
}

.landing__technology--small {
  max-width: 126px;
  margin-top: 75px;

  .landing__technology-img-wrap {
    height: 64px;
  }

  .landing__technology-img {
    width: auto;
  }

  .landing__technology-title {
    color: #999999;
  }
}

//New feature

.landing__new-feature {
  padding-bottom: 50px;
  margin-top: 105px;
  margin-bottom: -105px;
  text-align: center;
}

.landing__new-feature-title {
  margin-bottom: 16px;
  font-weight: 700;

  & + p {
    color: #999999;
    font-size: 16px;
    line-height: 1.8;
    margin-top: 16px;
  }
}

.landing__new-feature-img {
  margin-top: 50px;

  img {
    border-radius: 10px;
    box-shadow: 0 18px 30px 0 rgba(28, 50, 61, 0.14);
  }
}

//Code

.landing__code {
  margin-top: 105px;
  flex-direction: row;
  padding-bottom: 100px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  &.landing__code--first {
    margin-top: 200px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .landing__code-text {
      text-align: center;
      margin-left: 30px;

      @media screen and (min-width: 768px) {
        text-align: left;

        .landing__section-title {
          text-align: left;
        }
      }
    }
  }
}

.landing__code-img, .landing__code-text {
  height: 100%;
  display: flex;
}

.landing__code-img {
  position: relative;

  &.landing__code-img--no-shadow {

    img {
      box-shadow: none;
    }
  }
}

.landing__img {
  box-shadow: 0 18px 25px 0 rgba(28, 50, 61, 0.1);
  margin-top: 30px;

  @media screen and (min-width: 768px) {
    margin: 0;

    &.landing__img--over {
      margin: -50px 0 -100px -150px;
      width: calc(100% + 150px);
    }

    &.landing__img--over-right {
      margin: -50px -150px -100px 0;
      width: calc(100% + 150px);
    }
  }
}

.landing__code-text {
  margin-bottom: 30px;
  text-align: center;
  margin-left: auto;
  margin-right: 30px;

  .landing__section-title {
    margin-bottom: 16px;
    font-weight: 500;
  }

  p {
    color: #999999;
    font-size: 16px;
    line-height: 1.8;
    margin-top: 16px;
  }

  @media screen and (min-width: 768px) {
    max-width: 360px;
    text-align: left;
    margin-bottom: 0;
    margin-right: 30px;

    .landing__section-title {
      text-align: left;
    }
  }
}

.landing__code-wrap {
  margin-top: auto;
  margin-bottom: auto;

  &.landing__code-wrap--rounded {

    img {
      border-radius: 10px;
    }
  }
}

.landing__code-update {
  height: 54px;
  margin-bottom: 20px;
  position: relative;

  .landing__code-update-text {
    color: #bcc8d3;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.8;
  }
}

.landing .landing__seed {
  color: #2c86a2;
  font-size: 24px;
  width: 100%;
  max-width: 490px;
  text-align: center;
  margin: 80px auto 30px auto;
  line-height: normal;
  opacity: 0.9;
}

//Demo

.landing__demo-wrap {
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0px;
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;

    .landing__demo-theme-toggle, .landing__demo-toggle-wrap {
      justify-content: flex-end;
      text-align: right;
    }

    .landing__demo-theme-toggle {
      padding-left: 0;
      padding-right: 130px;

      &:before {
        left: auto;
        right: 0;
      }
    }
  }
}

.landing__demo-theme-toggle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  padding-left: 130px;
  position: relative;

  &:before {
    content: "";
    height: 2px;
    width: 100px;
    background: $landing-accent-color;
    position: absolute;
    left: 0;
    transform: translateY(-15px);
  }

  .landing__demo-title {
    width: 100%;
    font-size: 18px;
    line-height: 1.44;
    color: $landing-accent-color;
    margin: auto 0 0;
  }
}

.landing__demo {
  text-align: center;
  display: block;

  &:hover {

    .landing__demo-img-wrap {

      img {
        box-shadow: none;
      }

      &:before {
        opacity: 0.78;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.landing__demo-img-wrap {
  position: relative;

  img {
    width: 100%;
    box-shadow: 0 18px 25px 0 rgba(28, 50, 61, 0.08);
    transition: 0.3s;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $landing-gradient;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:after {
    content: 'Live demo';
    position: absolute;
    transition: opacity 0.3s;
    opacity: 0;
    font-size: 14px;
    color: #ffffff;
    line-height: 14px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    top: calc(50% - 7px);
    font-weight: 500;
    left: 0;
  }
}

.landing__demo-toggle-wrap {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: auto;

  span {
    color: $color-additional;
    font-size: 14px;
    line-height: 1.86;
  }
}

.landing__demo-toggle {
  width: auto;
  margin: 0 15px;

  .toggle-btn__input-label {
    background-color: #eff1f5 !important;
    width: 40px;
    height: 24px;

    &:after {
      background: $color-accent;
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-width: 1200px) {

  .landing__demo-theme-toggle {
    padding-left: 80px;

    &:before {
      width: 50px;
    }
  }

  .landing__demo-wrap:nth-child(odd) .landing__demo-theme-toggle {
    padding-right: 80px;
    padding-left: 0;
  }
}

@media screen and (max-width: 992px) {

  .landing__demo-wrap {

    &, &:nth-child(odd) {

      .landing__demo-theme-toggle, .landing__demo-toggle-wrap {
        justify-content: center;
        text-align: center;
      }
    }
  }

  .landing__demo-theme-toggle .landing__demo-title {
    margin-top: 25px;
  }

  .landing__demo-theme-toggle {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  .landing__demo-wrap:nth-child(odd) .landing__demo-theme-toggle {
    padding-right: 0;
  }
}

//Feature Request

.landing .landing__request {
  color: #2c86a2;
  font-size: 24px;
  width: 100%;
  max-width: 750px;
  text-align: center;
  margin: 0 auto 60px auto;
  line-height: normal;
  opacity: 0.9;
}

//Feature

.landing__features-wrap {
  margin: 0 auto -50px auto;
  display: flex;
  max-width: 670px;
  width: 100%;
  flex-wrap: wrap;

  &.landing__features-wrap--more {
    max-width: 900px;
    justify-content: space-between;
    margin-bottom: -25px;

    .landing__feature-more {
      color:  #6a8287;
      font-size: 16px;
    }
  }
}

.landing__feature-more {
  max-width: 230px;
  width: 100%;
  margin: 0 0 25px;

  svg {
    width: 16px;
    height: 16px;
    fill: $color-accent;
    margin-right: 20px;
  }
}

.landing__feature {
  width: 50%;
  padding-left: 75px;
  padding-right: 16px;
  position: relative;
  margin-bottom: 50px;

  .landing__feature-title {
    color: $landing-accent-color;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 500;
    margin-top: 0;
  }

  .landing__feature-caption {
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {

  .landing__features-wrap {
    max-width: 400px;
  }

  .landing__feature {
    width: 100%;
    padding-right: 0;

    &:nth-child(odd) {
      order: 0;
    }

    &:nth-child(even) {
      order: 1;
    }
  }
}

@media screen and (max-width: 520px) {

  .landing__features-wrap.landing__features-wrap--more {
    max-width: 230px;

    .landing__feature-more {
      margin-bottom: 25px;
    }
  }
}

.landing__feature-img-wrap {
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fafafa;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 24px;
    width: auto;
  }
}

.landing__center-btn {
  text-align: center;
  margin-top: -20px;
  height: 40px;
}

//Footer

.landing__footer {
  text-align: center;
  position: relative;

  a {
    color: $landing-accent-color;
    transition: 0.3s;

    &:hover {
      color: darken($landing-accent-color, 10%);
    }
  }

  .landing__footer-text {
    color: #a0a0a0;
    opacity: 0.9;
    font-size: 14px;
    line-height: normal;
    padding-bottom: 50px;
    padding-top: 120px;
  }
}

.landing__footer-background {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 95%;
  height: 2030px;
  transition: 0.3s;

  @media screen and (min-width: 480px) {
    width: 85%;
  }

  @media screen and (min-width: 576px) {
    width: 85%;
    height: 1630px;
  }

  @media screen and (min-width: 768px) {
    width: 75%;
    height: 1230px;
  }

  @media screen and (min-width: 992px) {
    width: 60%;
  }

  @media screen and (min-width: 1200px) {
    width: 45%;
  }
}

// Testimonials

.landing__testimonials {
  margin-bottom: -30px;
}

.landing__testimonial {
  padding-top: 25px;
  box-shadow: 0 15px 20px 0 rgba(57, 99, 112, 0.06);
  background-color: white !important;
}

.landing .landing__testimonial-name {
  color: $landing-accent-color;
  margin-bottom: 10px;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
}

.landing__testimonial-stars {
  margin-bottom: 18px;

  svg {
    fill: $color-yellow;
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}

.landing .landing__testimonial-review {
  color: #94acb4;
  font-size: 13px;
  line-height: 1.54;
  margin: 0;
}
