
.filters-div {
    height: 42px;
    width: calc(100% - 10px);
    margin: 5px 10px 5px 0px;
    position: relative;
    overflow: hidden;
}

.filters-div .child-div{
    user-select: none;
    display: flex;
    width: 100%;
    height: 33px;
    position: absolute;
    overflow: auto;
    font-size: 14px;
    color: black;
    font-weight: bold;
    padding-bottom: 5px;
}

.child-div * {
    cursor: pointer;
    margin: 0;
    margin-right: 15px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.child-div:first-child {
    margin: 0;
    /* margin-top: 10px; */
    margin-right: 15px !important;
    margin-left: 10px;
}

.child-div > p {
    margin-right: 15px !important;
}

.filter-view-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 20px);
    height: 100px;
    margin-left: 10px;
}

.advnace-search-actions{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 10px;
}

.check-in-dates-calendars {
    display: flex;
}

@media (min-width: 800px) {
    .check-in-dates-calendars {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@media (max-width: 799px) {
    .check-in-dates-calendars {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}