.manage-plan-paper {
  margin-bottom: 15px;
  margin-right: 15px;
  @media (max-width: 1366px) {
    margin-right: 0;
  }
}

.manage-plan-heading-container {
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.manage-plan-content-container {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
}

.manage-plan-text {
  font-size: 16px;
  color: rgb(2, 122, 255);
  font-weight: 500;
  cursor: pointer;
  line-height: 1.75;
  animation-delay: 1s;
  &:hover {
    font-weight: 500;
  }
  > a {
    text-decoration: none;
  }
}
