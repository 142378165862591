
.loading-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
}

.main-api-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 700px;
    min-height: 700px;
    margin-left: 15px;
    /* background-color: #fff; */
}

.main-api-container .api-building-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.api-building-container .api-keys-container {
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    box-shadow: 0 6px 7px -4px transparent,0 11px 15px 1px transparent,0 4px 20px 3px rgba(0,0,0,.12)!important;
}

.api-keys-container .container-heading {
    font-weight: bolder;
    text-transform: capitalize;
    font-size: 18px;
    color: #23201f !important;
}

.api-keys-container * {
    margin: 6px 0 6px 0;
}

.api-keys-container .api-key-information {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.api-key-information p {
    margin: inherit;
}

.api-key-information .api-key {
    color: #027aff;
    cursor: pointer;
    word-break: break-all;
}

.api-key-information .api-key-button {
    border: none;
    background-color: #027aff;
    color: #fff;
    border-radius: 5px;
    height: 30px;
}

.api-keys-container .api-client-information {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.api-client-information p {
    margin: inherit;
}

.api-client-information .client-id {
    color: #027aff;
    cursor: pointer;
    word-break: break-all;
}

.api-keys-container .quota-information {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    height: 100%;
}

.quota-information .error-message {
    align-self: center;
    justify-self: center;
}

.quota-information .warning-message {
    color: red;
    margin: 0;
    position: absolute;
    bottom: 0;
    margin-bottom: -10px;
}

.warning-message span {
    color: #027aff;
    font-weight: bold;
    text-decoration: underline;
    /* cursor: pointer; */
    opacity: 0.5;
}

.quota-information .quota-information-container {
    display: flex;
    width: calc(100% - 20px);
    height: 150px;
    margin-left: 20px;
    align-items: center;
    justify-content: space-between;
}

.quota-information .calls-stats {
    display: flex;
    flex-direction: column;
    width: 180px;
}

.calls-stats .each-stat {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.each-stat p {
    margin: 0;
}

.api-building-container .bulding-listing-container {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    height: 100%;
    margin-left: 15px;
    padding: 6px 15px 0 15px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    box-shadow: 0 6px 7px -4px transparent,0 11px 15px 1px transparent,0 4px 20px 3px rgba(0,0,0,.12)!important;
}

.bulding-listing-container .heading-search-container {
    display: flex;
    justify-content: space-between;
}

.bulding-listing-container .table-contents-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100% - 60px);
    margin-top: 10px;
    width: 100%;
}

.heading-search-container .container-heading {
    font-weight: bolder;
    text-transform: capitalize;
    font-size: 18px;
    margin-bottom: 6px;
    color: #23201f !important;
}

.heading-search-container .search-field {
    margin-top: -4px;
    width: 60%;
}

.table-contents-container .table-pagination{
    /* height: 100px; */
    user-select: none;
    padding: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-bottom: -15px;
}

@media(max-width: 1100px) {
    .main-api-container {
        height: auto;
        min-height: auto;
    }
    .main-api-container .api-building-container {
        flex-direction: column;
        height: auto;
    }

    .api-building-container .api-keys-container {
        height: auto;
        width: calc(100% - 30px);
    }

    .api-building-container .bulding-listing-container {
        width: calc(100% - 30px);
        margin-top: 10px;
        height: 680px;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .table-pagination { 
        margin-bottom: -20px;
    }
}

@media(max-width: 470px) {
    .main-api-container .api-building-container {
        flex-direction: column;
        height: auto;
    }
    .api-building-container .api-keys-container {
        height: auto;
        width: calc(100% - 30px);
    }
    .quota-information .quota-information-container {
        flex-direction: column;
        margin-left: 0;
        height: 250px;
    }
    .quota-information-container .quota-donut-chart {
        margin-top: -30px;
    }
    .api-building-container .bulding-listing-container {
        height: 680px;
    }
}

@media(max-width: 446px) {
    .main-api-container .api-building-container {
        height: auto;
    }
    .api-building-container .bulding-listing-container {
        height: 680px;
    }
}

@media(max-width: 415px) {
    /* .api-building-container .api-keys-container {
        height: 760px;
    } */
    .api-key-information .api-key-button {
        height: 35px;
    }
}

@media(max-width: 390px) {
    .main-api-container .api-building-container {
        height: auto;
    }

    .api-building-container .bulding-listing-container {
        height: 700px;
    }
    
    .bulding-listing-container .table-contents-container {
        height: calc(100% - 95px);
    }

    .bulding-listing-container .heading-search-container {
        flex-direction: column;
    }

    .heading-search-container .search-field {
        width: 100%;
        margin-left: -5px;
    }
}

@media(max-width: 365px) {
    /* .main-api-container .api-building-container {
        height: auto;
    } */
    .api-building-container .api-keys-container {
        height: 680px;
    }
    .api-building-container .bulding-listing-container {
        height: 700px;
    }
}

@media(max-width: 361px) {
    /* .main-api-container .api-building-container {
        height: auto;
    } */

    .api-building-container .bulding-listing-container {
        height: 700px;
    }
    
    .api-key-information .api-key-button {
        height: 35px;
    }
}