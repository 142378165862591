.custom-stepper-container {
    height: inherit;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.arrow-next {
    margin-left: 5px;
}
.arrow-back {
    transform: rotate(180deg);
    margin-right: 5px;
}

.next-previous-btn {
    width: 130px;
    cursor: pointer;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 1);
}

.next-previous-btn:hover {
    width: 130px;
    cursor: pointer;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 48, 48);
    font-weight: 600;
}
.next-previous-btn-disable{
    width: 130px;
    cursor: default;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 1);
}

.stepper-progress-section{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
}

.stepper-progress {
    height: 15px;
    display: flex;
    margin: 0 3px;
}

.stepper-checked {
    border-bottom: 3px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
}

.stepper-unchecked {
    border-bottom: 3px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0);
}