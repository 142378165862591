.display-flex {
  display: flex;
  bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
}

.display-flex-column {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 374px) {
 
  .display-flex-column {
    width: auto;
  }

}

.table-pagination {
  /* height: 100px; */
  user-select: none;
  /* padding: 10px; */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  bottom: 0;
}

.font-in-bold {
  font-weight: bold;
}

.justify-center {
  align-self: center;
}

.table-header {
  user-select: none;
  table-layout: auto;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.table-header:hover {
  cursor: pointer;
}

.table-row {
  color: rgb(59, 59, 156);
  background-color: white;
}

.table-row-active {
  color: white !important;
  background-color: #027aff !important;
}

.table-previous-page {
  padding-right: 3px;
}

.table-row,
.table-row-active {
  user-select: none;
  table-layout: auto;
  border: none;
  height: 30px !important;
  width: 38px !important;
  min-width: 38px !important;
  text-align: center;
  padding-top: 5px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  margin-top: 5px;
  /* border: 1px solid #dddddd; */
}

.table-row:hover,
.table-row-active:hover {
  cursor: pointer;
}

.table-button {
  pointer-events: none;
}

.showing-records {
  padding-top: 1px;
  padding-right: 5px;
}

/* .showing-records { */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
/* } */

.in-center {
  text-align: center;
}

.button-width {
  width: auto !important;
  max-width: auto !important;
  min-width: 38px !important;
}
