.manage-plan-container {
    padding: 5px 30px;
}

.manage-plan-container-inner {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.manage-plan-features-column {
    border-right: 1px solid rgb(206, 211, 224);
    border-top: 1px solid rgb(206, 211, 224);
}

.manage-plan-splitter {
    width: 810px;
    overflow-x: auto;
    display: flex;
}

.manager-feature-heading {
    color: #23201f !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    text-transform: capitalize !important;
}

.manager-feature-heading-top {
    color: #23201f !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    text-transform: uppercase !important;
}

.manage-plan-features-start-column {
    border-left: 1px solid rgb(206, 211, 224);
}

.manage-plan-features-start-bottom {
    border-left: 1px solid rgb(206, 211, 224);
}

.manage-plan-features-list{
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;
}

.manage-plan-general-features-headings {
    width: 200px !important;
    padding: 20px;
    font-size: 14px;
    max-height: 70px;
    min-height: 70px;
    min-width: 70px;
    border-bottom: 1px solid rgb(206, 211, 224);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.416667px;
    color: rgb(35, 47, 87);
    min-height: 70px;
    max-height: 70px;
    text-align: center;
    @media (max-width: 480px) {
        width: 140px !important;
        font-size: 12px;
    }
}

.manage-plan-general-features {
    width: 200px;
    padding: 20px;
    font-size: 14px;
    max-height: 70px;
    min-height: 70px;
    min-width: 70px;
    border-bottom: 1px solid rgb(206, 211, 224);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.416667px;
    color: rgb(35, 47, 87);
    min-height: 70px;
    max-height: 70px;
    text-align: center;
    @media (max-width: 480px) {
        width: 170px !important;
        font-size: 12px;
    }
}

.tick-cross-img {
    width: 25px;
    height: 25px;
}

.pricing-footer {
    text-align: center;
    padding: 20px;
}

.manage-plan-spinner-section {
    width: 100%;
    height: 100vh;
}

.manage-plan-starter {
    background-color: #00B67B !important;
    color: #fff !important;
}

.manage-plan-basic {
    background-color: #804DDF !important;
    color: #fff !important;
}

.manage-plan-premium {
    background-color: #FFB02F !important;
    color: #fff !important;
}
.manage-plan-enterprise {
    background-color: #FF1E59 !important;
    color: #fff !important;
}

.manage-plan-selected {
    background: silver;
    >li {
        border-bottom: 1px solid silver;
    }
}
