.saveBtn {
    height: 35px !important;
    width: 160px !important;
    border-radius: 3px !important;
    background-color: #027AFF !important;
    color: #FFFFFF !important;
    text-transform: uppercase;
    font-size: 13px !important;
    font-weight: bolder;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 5px 0 rgba(0,0,0,0.26);
}

.addMailroom {
    height: 35px !important;
    width: 200px !important;
    border-radius: 3px !important;
    background-color: #027AFF !important;
    color: #FFFFFF !important;
    text-transform: uppercase;
    font-size: 13px !important;
    font-weight: bolder;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16), 0 2px 5px 0 rgba(0,0,0,0.26);
}

.bold-text {
    color: #000000 !important;
    font-family: Roboto;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 10px;
    text-transform: capitalize;
    position: relative;
}
 