.tooltip-style {
    background-color: #fff !important;
     color: #000 !important;
     height: 25px !important;
     border: 1px solid black;
     border-radius: 0px;
}

.tooltip-style * {
    margin: 0 !important;
    border: none;
}

