logo_darkbody ::selection {
  color: #ffffff;
  background: #000000;
}

.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

*:focus {
  outline: 0 !important;
}

.phone-input:focus {
  box-shadow: none;
  border-bottom: 2px solid rgb(2, 122, 255) !important;
}

.flag-dropdown {
  margin-bottom: 2px !important;
}

.invalid-number:focus {
  border: none;
}

.trial-modal-image{
  width: 95%;
  height: 100%;
  margin-right: 20px;
}

.trial-modal-container {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.trial-modal-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trial-modal-heading {
  color: rgb(35, 32, 31);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.trial-modal-paragraph {
  color: rgb(37, 39, 40);
  margin-bottom: 20px;
}

.trial-modal-button {
    height: 40px;
    width: 100%;
    margin-top: 10px!important;
    background-color: #ff1e59 !important;
    border-radius: 4px!important;
    text-transform: capitalize!important;
    color: #fff!important;
    font-weight: 400!important;
    margin-bottom: 30px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container__wrap {
  padding-left: 0;
  padding-top: 80px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  @include themify($themes) {
    // background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 240px;
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.highcharts-tick {
  display: none;
}

.highcharts-grid-line {
  display: none;
}

.dropdown-arrow {
  height: 20px;
  width: 20px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: initial;
  border-radius: 0.25rem;
}

.barchart-div1 {
  margin-bottom: -13px;
  display: flex;
  height: 100%;
  align-items: center;
  width: calc(100% - 50px);
}

.barchart-div2 {
  margin-bottom: -5px;
  margin-top: 1px;
  display: flex;
  height: 100%;
  align-items: center;
  width: calc(100% - 140px);
}

.barchart-paper {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 26px;
  padding-bottom: 26px;
  position: relative;
  height: 342px;
}

.building-associates-paper {
  max-height: 428px;
  margin-bottom: 15px;
  overflow-y: auto;
}

.highcharts-background {
  height: 23px !important;
  fill: transparent !important;
}

.package-thumbnail {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  cursor: pointer;
}

.package-image {
  margin-left: 15px;
  height: auto;
  width: 85%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 20px 20px 0px;
  margin-bottom: 20px;
}

.package-image-grid {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
}

.package-next-button {
  top: 220px;
  left: 43%;
  color: black;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 10px 20px 0px;
}

.snapSend-package-next-button {
  position: absolute !important;
  top: 40% !important;
  width: 20px !important;
  height: 20px !important;
  right: 0%;
  color: white !important;
  border: 3px solid !important;
}

.snapSend-package-next-button {
  @media screen and (min-width: 425) {
    right: 13% !important;
  }
  @media screen and (max-width: 960) {
    right: 13% !important;
  }
}

.small-view-snapSend-image {
  padding: 5px !important;
  object-fit: cover !important;
  height: 80px !important;
  width: 80px !important;
  border-radius: 15px;
}

.forward-action-container-outer-header {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  display: block;
  width: inherit;
}

.forward-action-container-header {
  background-color: #f5f5f5ab;
  width: 100%;
  height: auto;
  border-bottom: ridge;
  border-radius: 10px;
}

.forward-action-container-body-data-row-1a {
  width: auto;
  padding-left: 20px;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #212121;
  opacity: 1;
}

.forward-action-container-body-data-row-1b {
  font-size: 13px;
}

.forward-action-container-body-data-row-1c {
  width: auto;
  padding-left: 20px;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.26px;
  color: #212121;
  opacity: 1;
}

.forward-action-container-body-data-row-2a {
  width: auto;
  padding-left: 20px;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.26px;
  color: #212121;
  opacity: 1;
}

.forward-action-container-body-data-row-2b {
  font-size: 13px;
}

.forward-action-container-body-data-row-2c {
  width: auto;
  padding-left: 20px;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.26px;
  color: #212121;
  opacity: 1;
}

.forward-action-container-body-data-row-3a {
  width: auto;
  padding-left: 20px;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.26px;
  color: #212121;
  opacity: 1;
}

.forward-action-container-body-data-row-3b {
  font-size: 13px;
}

.forward-action-container-body-data-row-3c {
  width: auto;
  padding-left: 20px;
  height: fit-content;
  display: block;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.26px;
  color: #212121;
  opacity: 1;
}

.forward-action-header {
  display: flex;
  position: absolute;
  top: 2%;
  right: 13%;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    width: 93px !important;
    height: 30px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    width: 78px !important;
    height: 25px !important;
  }
  width: 65px;
  height: 20px;
}

.scan-send-action-header {
  display: flex;
  position: absolute;
  top: 2%;
  right: 13%;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    width: 112px !important;
    height: 30px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    width: 98px !important;
    height: 25px !important;
  }
  width: 80px;
  height: 20px;
}

.destroy-action-header {
  display: flex;
  position: absolute;
  top: 2%;
  right: 13%;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    width: 90px !important;
    height: 30px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    width: 75px !important;
    height: 25px !important;
  }
  width: 60px;
  height: 20px;
}

.hold-action-header {
  display: flex;
  position: absolute;
  top: 2%;
  right: 13%;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    width: 90px !important;
    height: 30px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    width: 75px !important;
    height: 25px !important;
  }
  width: 60px;
  height: 20px;
}

.forward-action-arrow {
  @media screen and (min-width: 630px) and (max-width: 960px) {
    border-top: 15px solid transparent !important;
    border-bottom: 15px solid transparent !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    border-top: 14px solid transparent !important;
    border-bottom: 11px solid transparent !important;
  }
  border-top: 11px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 7px solid #feb02f;
}

.scan-send-action-arrow {
  @media screen and (min-width: 630px) and (max-width: 960px) {
    border-top: 16px solid transparent !important;
    border-bottom: 14px solid transparent !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    border-top: 15px solid transparent !important;
    border-bottom: 10px solid transparent !important;
  }
  border-top: 11px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 7px solid #3380da;
}

.destroy-action-arrow {
  @media screen and (min-width: 630px) and (max-width: 960px) {
    border-top: 15px solid transparent !important;
    border-bottom: 15px solid transparent !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    border-top: 13px solid transparent !important;
    border-bottom: 11px solid transparent !important;
  }
  border-top: 11px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 7px solid #fd1d59;
}

.hold-action-arrow {
  @media screen and (min-width: 630px) and (max-width: 960px) {
    border-top: 16px solid transparent !important;
    border-bottom: 14px solid transparent !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    border-top: 16px solid transparent !important;
    border-bottom: 11px solid transparent !important;
  }
  border-top: 11px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 7px solid #804ddf;
}

.forward-action-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  color: white;
  background-color: #feb02f;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    font-size: 12px !important;
  }
  font-size: 9px;
}

.scan-send-action-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  color: white;
  background-color: #3380da;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    font-size: 12px !important;
  }
  font-size: 9px;
}

.destroy-action-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  color: white;
  background-color: #fd1d59;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    font-size: 12px !important;
  }
  font-size: 9px;
}

.hold-action-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  color: white;
  background-color: #804ddf;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    font-size: 12px !important;
  }
  font-size: 9px;
}

.package-prev-button {
  top: 270px;
  left: -47%;
  color: black;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 10px 20px 0px;
}

.snapSend-package-prev-button {
  position: absolute !important;
  top: 40% !important;
  width: 20px !important;
  height: 20px !important;
  left: -5% !important;
  color: white !important;
  border: 3px solid !important;
}

.paddingleft-10px {
  padding-left: 100px;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.courier-labels {
  position: absolute;
  display: flex;
  left: 17px;
  flex-direction: column;
  justify-content: space-evenly;
  top: 80px;
  margin-top: 0px;
}

.tooltip {
  position: absolute;
  top: 0;
  color: white;
  padding-top: 36px;
  padding-right: 120px;
}

.cancel-icon {
  height: 14px;
  width: 14px;
  font-size: 14px !important;
  color: #000;
  margin: -5px;
  border-radius: 50px;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-350px {
  width: 350px;
}

.width-375px {
  width: 375px;
}

.date-filter {
  display: flex;
  //margin-top: 10px;
  //margin-left: 5px;
}

.data-range-label {
  font-size: 13px;
  font-weight: 500;
  padding: 6px;
  color: #000;
  text-transform: uppercase;
}

.truncated-heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncated-heading-member {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 150px !important;
}

.tooltip:hover {
  height: 20px;
  width: 100%;
  position: absolute;
  display: none;
}

.tooltip:hover {
  display: block;
}

.min-width-auto {
  min-width: auto !important;
}

.min-width-100p {
  min-width: 100%;
}

.min-width-150px {
  min-width: 150px;
}

.width-auto {
  width: auto !important;
}

.width-30pc {
  width: 30%;
}

.width-55pc {
  width: 55%;
}

.width-91 {
  width: 91%;
}

.width-90 {
  width: 90%;
}

.width-100 {
  width: 100% !important;
}

.width-5p {
  width: 5%;
}

.width-15p {
  width: 15%;
}

.width-20p {
  width: 20%;
}

.width-60p {
  width: 60%;
}

.width-65p {
  width: 65%;
}

.width-75p {
  width: 75% !important;
}

.width-18px {
  width: 18px;
}

.width-38px {
  width: 38px !important;
}

.width-48px {
  width: 48px;
}

.width-50px {
  width: 50px;
}

.width-60px {
  width: 60px;
}

.width-75px {
  width: 75px;
}

.width-89px {
  width: 89px;
}

.width-95px {
  width: 98px;
}

.width-100px {
  width: 100px;
}

.width-110px {
  width: 110px;
}

.width-115px {
  width: 115px;
}

.width-120px {
  width: 120px;
}

.width-135 {
  width: 135px;
}
.width-130 {
  width: 130px;
}
.width-137 {
  width: 137px;
}

.width-140 {
  width: 140px;
}

.width-150 {
  width: 150px !important;
}

.width-165 {
  width: 165px;
}

.width-170 {
  width: 170px !important;
}

.width-190 {
  width: 190px;
}

.width-200px {
  width: 200px;
}

.width-250px {
  width: 250px;
}

.width-200-160-130 {
  width: 200px !important;
}

.building-cart-paper {
  padding: 17px;
  height: 460px;
}

.top-building-heading {
  font-size: 18px;
  font-weight: 500;
  color: black !important;
}

.buildings-zero-message {
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.img-18-1 {
  height: 18px;
  width: 18px;
  margin-top: 1px;
}

.buildings-zero {
  position: absolute;
  z-index: 1;
  bottom: 11%;
  left: 5%;
  color: #666666;
}

.packages-heading-height {
  height: 57px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.change-password-paper {
  height: 490px;
}

.margin-m6px {
  margin: -6px !important;
}

.margin-0px {
  margin: 0px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-15 {
  margin: 15px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-top-bottom-10p {
  margin: 10px 0px !important;
}

.margin-top-20px {
  margin-top: 20px !important;
}

.margin-top-m20px {
  margin-top: -7px !important;
}

.fixed-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 58px;
  right: 35px;
  bottom: 25px;
}

.table-heading {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.mailroom-cells {
  width: 115px;
  padding: 0px !important;
}

.mailroom-cells-code {
  width: 85px;
  padding: 0px !important;
}

.mailroom-data {
  font-size: 14px;
  line-height: 14px;
  padding: 0px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-absolute {
  position: absolute;
  height: 40px;
  width: 100%;
  top: 0px;
  left: 0;
}

.associate-data {
  font-size: 14px;
  line-height: 16px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.mailroom-btn {
  line-height: 14px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  margin-left: 10px !important;
  margin-bottom: 2px !important;
}

.mailrooms-list {
  width: 100% !important;
  padding: 0px !important;
  overflow-y: auto !important;
  height: 80%;
}

.mailrooms-heading {
  width: 100% !important;
  padding: 0px !important;
  overflow-y: auto !important;
}

.mailroom-title {
  font-weight: 500;
  line-height: 21px;
  text-transform: capitalize;
}

.capitalize {
  text-transform: capitalize !important;
  align-items: center;
}

.uppsercase {
  text-transform: uppercase;
}

.padding-5px {
  padding: 5px !important;
}

.padding-10px {
  padding: 10px;
}

.padding-14-4-4-4 {
  padding: 14px 4px 4px 4px;
}

.padding-15px {
  padding: 15px !important;
}

.padding-20px {
  padding: 20px;
}

.padding-left-right-8px {
  padding: 0px 8px !important;
}

.padding-left-right-5px55px {
  padding: 5px 55px !important;
}

.padding-left-20 {
  padding-left: 30px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-12 {
  padding-left: 12px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-8px {
  padding: 8px;
}

.padding-15px {
  padding: 15px;
}

.padding-0 {
  padding: 0px !important;
}

.padding-10-0 {
  padding: 10px 0px !important;
}

.padding-15-0 {
  padding: 15px 0px !important;
}

.padding-0-14px {
  padding: 0px 14px;
}

th {
  font-weight: 400 !important;
  text-transform: uppercase;
}

td a {
  color: #027aff !important;
}

.icon {
  color: grey;
  height: 32px !important;
  width: 32px !important;
  font-size: 30px !important;
}

.thumbnail-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
  cursor: pointer;
  width: 50px;
}

.padding-right-left-8 {
  padding: 0px 8px;
}

.padding-right-left {
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.paper {
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px,
    rgba(0, 0, 0, 0.12) 0px 4px 20px 3px !important;
}

.just-paper {
  box-shadow: rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px,
    rgba(0, 0, 0, 0.12) 0px 4px 20px 3px !important;
}
.scan-details-stats {
  height: 220px !important;
  padding: 17px !important;
}

.scan-details-stats {
  @media screen and (max-width: 425px) {
    height: 350px !important;
  }
}

.padding-left-10px {
  padding-left: 10px !important;
}

.padding-left-28px {
  padding-left: 28px !important;
}

.padding-left-45px {
  padding-left: 60px !important;
}

.padding-right-10x {
  padding-right: 10px !important;
}

.padding-right-left-bottom-15x {
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
}

.padding-left-60px {
  padding-left: 60px !important;
}

.packages-dropdown {
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid #e4e4e4 !important;
  height: 32px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  color: #747474 !important;
  opacity: 100 !important;
  font-weight: 400 !important;
  position: relative !important;
  overflow-x: hidden !important;
}

.packages-dropdown-text {
  display: flex !important;
  align-items: center !important;
  // float: left !important;
  height: 30px !important;
  overflow-x: hidden !important;
}

.padding-5-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.margin-right-m5p {
  margin-right: -5px !important;
}

.margin-left-5p {
  margin-left: 5px !important;
}

.margin-left-10p {
  margin-left: 10px !important;
}

.margin-left-m10p {
  margin-left: -10px !important;
}

.margin-left-12p {
  margin-left: 12px !important;
}

.margin-left-14p {
  margin-left: 14px !important;
}

.margin-left-right-10px {
  margin: 0px 10px !important;
}

.margin-left-right-20px {
  margin: 0px 20px !important;
}

.margin-left-right-m20px {
  margin: 0px -20px;
}

.DateRangePicker__CalendarSelection {
  background-color: #027aff !important;
  border: 1px solid #027aff !important;
}

.MuiInputBase-input-42 {
  font-size: 16px;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-18px {
  font-size: 16px !important;
}

.fs-20px {
  font-size: 16px !important;
}

.white {
  color: #ffffff;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-18px {
  font-size: 16px !important;
}

.fs-20px {
  font-size: 16px !important;
}

.blue {
  color: rgb(2, 122, 255) !important;
}

.bg-blue {
  background-color: rgb(2, 122, 255) !important;
}

.black {
  color: #000000 !important;
}

.yellow {
  color: rgb(255, 176, 47) !important;
}

.green {
  color: rgb(2, 182, 124) !important;
}

.bg-green {
  background-color: rgb(2, 182, 124) !important;
}

.red {
  color: rgb(255, 30, 89) !important;
}

.onboard-red > h2 {
  color: rgb(255, 30, 89) !important;
}

.onboard-red-close-icon > span {
  color: rgb(255, 30, 89) !important;
  font-size: 20px;
}

.bg-red {
  background-color: #e74c3c !important;
}

.bg-grey {
  background-color: #d8d8d8;
}

.grey-clr {
  color: #898b8a;
}

.grey {
  background-color: #f7f7f7;
}

.bg-moderate-grey {
  background-color: #d8d8d8;
}

.bg-black {
  background-color: #000;
}

.color-grey {
  color: #808080 !important;
}

.dark-grey-color {
  color: #383838 !important;
}

.bg-transparent {
  background-color: transparent;
}

.display-none {
  display: none !important;
}

.height-300px {
  height: 300px;
}

.avatar-div {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 20px;
}

.profile-icon {
  width: 21px;
  height: 21px;
  margin-bottom: 0px;
  color: grey;
}

.grid-switch-buttons {
  margin-left: 15px !important;
  height: 32px;
  width: 32px;
  padding: 0px !important;
  border-radius: 3px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 10px 0px, rgba(0, 0, 0, 0.26) 0px 2px 5px 0px;
}

.card-body {
  padding: 20px;
  height: 100%;
  border-radius: 5px;
}

.height-400-margin-right-bottom-10 {
  height: 400px;
  overflow: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

.select-search {
  @media screen and (max-width: 2600px) {
    min-width: 200px;
    max-width: 200px;
  }

  @media screen and (max-width: 770px) {
    min-width: 150px;
    max-width: 150px;
  }

  @media screen and (max-width: 450px) {
    min-width: 135px;
    max-width: 135px;
    div.input {
      font-size: 12px !important;
    }
  }
}

.select-search-info {
  @media screen and (max-width: 2600px) {
    min-width: 200px;
    max-width: 200px;
  }

  @media screen and (max-width: 770px) {
    min-width: 150px;
    max-width: 150px;
  }

  @media screen and (max-width: 450px) {
    min-width: 110px;
    max-width: 110px;
  }
}

.input-label {
  @media screen and (max-width: 2600px) {
    font-size: 14px !important;
    color: #b4b4b4 !important;
    font-weight: 400 !important;
  }

  @media screen and (max-width: 770px) {
    font-size: 12px !important;
    color: #b4b4b4 !important;
    font-weight: 400 !important;
  }
}

.item-text {
  @media screen and (min-width: 320px) and (max-width: 359px) {
    width: 126px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    position: relative !important;
    top: 5px !important;
  }
  @media screen and(min-width: 360px) and (max-width: 410px) {
    width: 160px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    position: relative !important;
    top: 5px !important;
  }
  @media screen and(min-width: 411px) and (max-width: 479px) {
    width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    position: relative !important;
    top: 5px !important;
  }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    width: 250px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    position: relative !important;
    top: 5px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 2599px) {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  @media screen and (max-width: 2600px) {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  @media screen and (max-width: 770px) {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.MuiInput-input-5793 {
  @media screen and (max-width: 2600px) {
    font-size: 14px !important;
    color: #b4b4b4 !important;
    font-weight: 400 !important;
  }

  @media screen and (max-width: 770px) {
    font-size: 12px !important;
    color: #b4b4b4 !important;
    font-weight: 400 !important;
  }
}

.MuiChip-root-554 {
  padding: -10px;
}

.language-icon {
  font-size: 13px !important;
  color: #027aff !important;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.highcharts-bar-series {
  span {
    font-size: 10px !important;
  }
}

.mailto-a {
  color: rgb(128, 128, 128) !important;
  font-size: 13px !important;
}

.footer-align-center {
  position: absolute !important;
  top: 80% !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  bottom: 10%;
  height: auto !important;
}

.cursor-none {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.position-absolute {
  position: absolute;
}

.absolute-bottom-0 {
  position: absolute;
  bottom: 0;
}

.popperSize {
  width: 165px !important;
  display: flex !important;
  margin-left: 8px !important;
}

.Select-icon {
  margin-left: 24px !important;
}

.padding-3246 {
  padding: 32px 46px;
}

.height-width-10p {
  height: 10% !important;
  width: 10% !important;
}

.height-width-p {
  height: 100%;
  width: 100%;
}

.height-width-20px {
  height: 20px !important;
  width: 20px !important;
}

.height-width-25px {
  height: 25px !important;
  width: 25px !important;
}

.height-width-35px {
  height: 35px !important;
  width: 35px !important;
}

.height-width-80px {
  height: 80px !important;
  width: 80px !important;
}

.height-width-100px {
  height: 100px !important;
  width: 100px !important;
}

.list-style-none {
  list-style: none;
}

.padding-right-5px {
  padding-right: 5px !important;
}

.padding-right-14px {
  padding-right: 14px !important;
}

.padding-right-15px {
  padding-right: 0px !important;
}

.padding-left-15px {
  padding-left: 0px !important;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-left-20 {
  margin-right: -20px;
  margin-left: -20px;
}

.margin-right-14 {
  margin-right: 14px;
}

.margin-right-26 {
  margin-right: 26px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-top-10px {
  margin-top: 10px !important;
}

.margin-top-24px {
  margin-top: 24px !important;
}

.margin-top-27px {
  margin-top: 27px !important;
}

.margin-top-60px {
  margin-top: 60px !important;
}

.margin-top-70px {
  margin-top: 70px !important;
}

.margin-070 {
  margin: 0px 85px;
}

.login-btn {
  height: 40px;
  width: 100%;
  margin-top: 25px !important;
  background-color: #ff1e59 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.phone-input {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.login-btn:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 6px 7px -4px, rgba(0, 0, 0, 0) 0px 11px 15px 1px,
    rgba(0, 0, 0, 0.12) 0px 11px 15px 1px !important;
}

.login-btn-disabled {
  height: 40px;
  width: 100%;
  margin-top: 25px !important;
  background-color: #e1e1e1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.white {
  color: #fff !important;
}

.pink {
  color: #ff1e59 !important;
}

.height-100vh {
  height: 100vh;
}

.dark-link {
  font-weight: bolder !important;
  color: #000000 !important;
  text-decoration: underline !important;
  padding: 0px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.dark-link:hover {
  background-color: transparent !important;
}

.margin-4px {
  margin: 4px !important;
}

.margin-top-m10px {
  margin-top: -10px !important;
}

.margin-top-m5px {
  margin-top: -5px;
}

.margin-top-bottom-4 {
  margin: 4px 0px;
}

.height-10px {
  height: 10px !important;
}

.height-25px {
  height: 25px !important;
}

.height-28px {
  height: 28px;
}

.height-60 {
  height: 60px;
}

.height-60-footer {
  min-height: 60px;
  height: auto;
}

.height-70 {
  height: 70px;
}

.height-75 {
  height: 75px;
}

.height-25p {
  height: 25% !important;
}

.height-80p {
  height: 80% !important;
}

.height-90p {
  height: 90%;
}

.height-95p {
  height: 95%;
}

.height-400p {
  height: 400px;
}

.max-height-32 {
  max-height: 32px !important;
}

.max-height-215px {
  max-height: 215px;
}

.max-height-250px {
  max-height: 250px;
}

.max-height-289px {
  max-height: 289px;
}

.max-height-310px {
  max-height: 310px;
}

.max-height-315px {
  max-height: 315px;
}

.max-height-400px {
  max-height: 400px;
}

.background-pink {
  background-color: #ff1e59 !important;
}

.background-grey {
  background-color: #e5e5e5;
}

.steppers {
  background-color: transparent !important;
}

.background-transparent {
  background-color: transparent !important;
}

.float-right {
  float: right;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.justify-align-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.heading {
  font-weight: bolder !important;
  color: #000000 !important;
}

.package-heading {
  font-size: 22px;
  font-weight: bold;
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  cursor: pointer;
}

.align-center-none {
  align-items: normal !important;
}

.align-center-100 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.align-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.padding-top-3px {
  padding-top: 3px !important;
}

.padding-11-16 {
  padding: 11px 16px;
}

.padding-top-10p {
  padding-top: 10px !important;
}
.padding-left-12p {
  padding-left: 12px !important;
}

.padding-bottom-30p {
  padding-bottom: 30px !important;
}

.disabled-link {
  pointer-events: none;
}

.margin-left-m25 {
  margin-left: -15px;
  margin-top: -2px;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.fs-1rem {
  font-size: 1rem;
}

.fs-7rem {
  font-size: 7rem !important;
}

.font-size-13 {
  font-size: 13px !important;
}
.font-size-11 {
  font-size: 11px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.fs-14-11 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-18p {
  font-size: 18px;
}

.font-size-22 {
  font-size: 22px !important;
}

div {
  font-family: Muli, sans-serif !important;
}

input {
  font-family: Muli, sans-serif !important;
}

th {
  font-family: Muli, sans-serif !important;
}

td {
  font-family: Muli, sans-serif !important;
}

a {
  font-family: Muli, sans-serif !important;
}

span {
  font-family: Muli, sans-serif !important;
}

p {
  font-family: Muli, sans-serif !important;
}

li {
  font-family: Muli, sans-serif !important;
}

label {
  font-family: Muli, sans-serif !important;
}

h1 {
  font-family: Muli, sans-serif !important;
}

h2 {
  font-family: Muli, sans-serif !important;
}

h3 {
  font-family: Muli, sans-serif !important;
}

h4 {
  font-family: Muli, sans-serif !important;
}

h5 {
  font-family: Muli, sans-serif !important;
}

tspan {
  font-family: Muli, sans-serif !important;
}

b {
  font-family: Muli, sans-serif !important;
}

input {
  color: #000 !important;
}

.login-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.overflow-auto {
  overflow-y: auto;
}
.overflowy-hidden {
  overflow-y: hidden;
}
.logo {
  width: 20%;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.border-radius-4px {
  border-radius: 4px;
}

.border-radius-25px {
  border-radius: 25px !important;
}

.border-radius-50px {
  border-radius: 50px !important;
}

.height-auto {
  height: auto;
}

.height-32px {
  height: 32px;
}

.height-40px {
  height: 40px;
}

.height-50px {
  height: 50px;
}

.height-60px {
  height: 60px;
}

.height-150px {
  height: 150px;
}

.height-100p {
  height: 100% !important;
}

.height-235px {
  height: 235px;
}

.height-315px {
  height: 315px;
}

.height-500px {
  height: 500px;
}

.align-up {
  align-items: flex-start;
}

.space-evenly {
  margin-top: 30px;
  flex: 1;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.just-space-between {
  display: flex;
  justify-content: space-between;
}

.space-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.space-between-members {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.space-between-with-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.space-between-100pc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.space-between-justify-spacebetween-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space-between-with-767-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.space-between-width-center {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.bottom-15px {
  bottom: 15px;
}

.before-search {
  margin: auto 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.search {
  width: 98% !important;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-size: 14px;
  border-radius: 6px;
  max-height: 32px;
}

.search-list-height {
  height: 50vh;
}

.toggle-search {
  width: 100%;
  margin-left: 0px;
}

.building-toggle-search {
  color: rgb(0, 0, 0) !important;
  font-size: 12px !important;
  max-height: 32px;
  border-radius: 4px;
  padding-left: 25px;
  padding-right: 0px !important;
  margin-left: -15px;
  margin-right: -75px;
}

.padding-10px {
  padding: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-0-15px {
  padding: 0px 15px;
}

.padding-0-25px {
  padding: 0px 25px;
}

.padding-top-bottom-0px {
  padding-top: 0px;
  padding-bottom: 0px;
}

.padding-top-bottom-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-top-bottom-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer {
  // position: absolute;
  width: 100%;
  bottom: 0;
}

.bg {
  position: absolute;
  opacity: 1;
}

.opacity-2 {
  opacity: 2;
}

.cross-btn {
  height: 14px !important;
  width: 14px !important;
  color: rgb(0, 0, 0);
  margin: -5px;
  border-radius: 50px;
}

.bin-btn-delete-associates {
  height: 20px !important;
  width: 20px !important;
  color: rgb(255, 30, 89);
  margin: -5px;
  border-radius: 50px;
}

.scan-detail-counts {
  font-size: 38px;
  display: flex;
  justify-content: center;
}

.scan-detail-label {
  color: rgb(117, 117, 117);
  font-size: 16px;
  font-weight: bold;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-center-16px-top {
  justify-content: center;
  display: flex;
  margin-top: 16px;
}

.flex-direction-column {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.overflowy-scroll {
  overflow-y: auto;
}

.overflowx-hidden {
  overflow-x: hidden;
}

.transparent-blue-button {
  line-height: 14px !important;
  color: rgb(2, 122, 255) !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  margin-left: 10px !important;
  margin-bottom: 2px !important;
  background-color: transparent !important;
}

.full-div {
  width: 100%;
  background-color: #ffffff;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.search-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.building-options-dropdown {
  position: absolute;
  width: 120px;
  z-index: 1;
  right: 10px;
}

.options-item {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  margin-bottom: 0px;
  cursor: pointer;
  font-weight: 600;
}

.min-height-30px {
  min-height: 30px !important;
}

.min-height-38px {
  min-height: 38px !important;
}

.min-height-400px {
  min-height: 400px;
}

.top-bottom {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  flex-wrap: wrap;
}

.space-between {
  align-items: center !important;
  display: flex !important;
  justify-content: space-between !important;
}

.currently-held {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 90%;
}

.scan-detail-div2 {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 90%;
}

.date-range-list-item {
  width: 100%;
  color: #000;
  font-size: 12px;
  text-transform: capitalize;
  background-color: transparent;
}

.border-on-large-screen {
  border-right: 1px solid #0000001f;
}

.currently-held-side-labels {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.align-bottom {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.custom-footer {
  align-items: flex-end;
  position: absolute;
  height: 100%;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.addBtn {
  margin-bottom: 0px;
  width: 180px !important;
  border-radius: 3px !important;
  background-color: #027aff !important;
  color: #ffffff !important;
  text-transform: uppercase;
  font-size: 13px !important;
  font-weight: bolder;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.pinkBg {
  background-color: #000000 !important;
  background-image: linear-gradient(90deg, rgb(46, 204, 113), rgb(243, 156, 18));
}

.redBg {
  background-color: #000000 !important;
  background-image: linear-gradient(90deg, red, #ff6565);
}

.greenBg {
  background-color: #000000 !important;
  background-image: linear-gradient(90deg, #58ff9f, #2dff86);
}

.blackBg {
  background-color: #000000 !important;
  background-image: linear-gradient(90deg, #000000, #000000);
}

.intro-banner-vdo-play-btn {
  position: relative;
  text-align: center;
  border-radius: 100px;
  z-index: 1;
}
.intro-banner-vdo-play-btn i {
  line-height: 56px;
  font-size: 30px;
}
.intro-banner-vdo-play-btn .ripple {
  position: absolute;
  width: 160px;
  height: 160px;
  z-index: -1;
  left: 50%;
  top: 50%;
  opacity: 0;
  margin: -80px 0 0 -80px;
  border-radius: 100px;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
}

.border-solid {
  border-bottom-style: solid !important;
}
.border-solid > .MuiInput-underline-159 :hover {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.87);

  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;

  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: border-bottom-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;

  pointer-events: none;
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.spinner {
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.onboard-modal > div > div {
  position: absolute;
  bottom: 20px;
  right: 40px;
}

.onboard-modal > div > div > div {
  padding: 0 20px 20px !important;
  padding-top: 15px !important;
}

.onboard-download-app-btn {
  height: 40px;
    width: 100%;
    margin-top: 10px!important;
    background-color: #ff1e59!important;
    border-radius: 4px!important;
    text-transform: capitalize!important;
    color: #fff!important;
    font-weight: 400!important;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.table-pagination {
  height: 70px;
  user-select: none;
  padding: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.font-in-bold {
  font-weight: bold;
}

.justify-center {
  align-self: center;
}

.table-cell-width {
  width: 20px !important;
}

@media only screen and (max-width:400px){
  .onboard-red{
    padding: 24px 0px 20px 24px;
  }
  .onboard-red-close-icon{
    margin-right:0px;
  }
  .onboard-download-app-btn{
    padding: 0px;
  }
  .onboard-modal > div > div {
    margin:8px;
  }
}

@media only screen and (max-width: 425px) {
  .onboard-modal > div > div {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
  }
  .margin-070 {
    margin: 0px 0px;
  }
  .login-footer {
    display: block !important;
  }
  .logo {
    width: 50%;
  }
  .font-size-14 {
    font-size: 10px !important;
  }
  .fs-14-11 {
    font-size: 11px !important;
  }
  .small-screen-center {
    display: flex;
    justify-content: center;
  }
  .margin-right-26 {
    margin-right: 20px;
  }
  .margin-m10 {
    margin-left: -10px;
  }
  .building-toggle-search {
    font-size: 10px !important;
  }
  .padding-right-15px {
    padding-right: 0px !important;
  }
  .padding-left-15px {
    padding-left: 0px !important;
  }
  .popover-div {
    display: flex;
    width: 510px;
    height: 280px;
  }
  .border-on-large-screen {
    border-right: none;
  }
  .space-between-members {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 472px) {
  .displayFlex {
    display: flex !important;
    flex-direction: column !important;
  }
  .space-between-members {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 572px) and (max-width: 626px) {
  .displayFlex {
    display: flex !important;
    flex-direction: column !important;
    font-size: 10px !important;
    height: 80px !important;
  }
  .space-between-members {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width:762px){
  .space-between-members {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 572px) and (max-width: 626px) {
  .fontSize {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 374px) and (max-width: 376px) {
  .font-size-16 {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 376px) {
  .width-350px {
    width: 280px;
  }
  .space-between-members {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 767px) and (max-width: 5000px) {
  .absolute-height-width-100pc {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 1020px) and (max-width: 1025px) {
  .font-size-12 {
    font-size: 10px !important;
    margin-right: 5px;
  }
  .margin-right-10px {
    margin-right: 0px;
  }
}
.barchart-div1 .highcharts-container {
  // width: inherit !important;
  height: 40px !important;
  @media only screen and (min-width: 300px) and (max-width: 600px) {
    width: 200px !important;
  }
}
.barchart-div2 .highcharts-container {
  height: 40px !important;
  @media only screen and (min-width: 300px) and (max-width: 600px) {
    width: 200px !important;
  }
}

@media only screen and (max-width: 426px) {
  .scan-detail-div2 {
    display: block !important;
  }
  .currently-held {
    display: block;
    width: 100%;
  }
}
@media only screen and (min-width: 960px) {
  .padding-right-15px {
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 426px) and (max-width: 769px) {
  .height-100v {
    height: 100vh;
  }
  .height-500px {
    height: 500px;
  }
  //.scan-detail-counts {
  //  font-size: 24px;
  //  display: flex;
  //  justify-content: center;
  //}
  //.scan-detail-label {
  //  color: rgb(117, 117, 117);
  //  font-size: 16px;
  //  font-weight: bold;
  //}
  //.scan-detail-div2 {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //}
  //.flex-column-center {
  //  width: 100%;
  //  display: flex;
  //  justify-content: space-between;
  //  flex-direction: row;
  //}
  //.scan-detail-div2 {
  //  width: 100%;
  //}
  //.currently-held {
  //  display: block;
  //  width: 100%;
  //}
  .padding-right-15px {
    padding-right: 0px !important;
  }
  .padding-left-15px {
    padding-left: 0px !important;
  }
  .width-200-160-130 {
    width: 160px !important;
  }
  .height-400-margin-right-bottom-10 {
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 321px) {
  .font-size-12 {
    font-size: 10px !important;
    list-style: none !important;
  }
  .scan-detail-div2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .space-between-with-block {
    display: block;
  }
  .width-270px {
    width: 270px !important;
  }
  .width-200-160-130 {
    width: 130px !important;
  }
  .height-400-margin-right-bottom-10 {
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .search-list-height {
    height: 78vh;
  }
  .package-dialog-footer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .space-between-width-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .space-between-with-767-block {
    display: block;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .margin-right-10px {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 980px) {
  .space-between-with-block-980 {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .space-between-with-block-768 {
    display: block !important;
  }
  .margin-right-10px {
    margin-right: 0px;
  }
}

.rrui__input-field,
.rrui__select__button {
  padding-bottom: 6px !important;
}

.react-phone-number-input__error {
  color: #ff1e59 !important;
}
.MuiInputBase-input {
  font-size: 13px !important;
}

.flex-view {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-view-space {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.truncate-option {
  max-width: 150px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.list-item-text {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mailroom-scroll {
  height: 205px;
  overflow-y: auto;
}

.global-search-truncation {
  @media screen and (max-width: 2500px) {
    font-weight: 500;
    font-size: 14px;
  }

  @media screen and (max-width: 450px) {
    max-width: 160px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-weight: 500;
    font-size: 14px;
  }
}
.font-input {
  font-size: 14px !important;
}
.react-tel-input input[type="tel"] {
  font-size: 14px !important;
}

.search-bottom {
  margin-bottom: 10px;
}

.start-adorment {
  pointer-events: none !important;
  cursor: default;
}

.label-width-auto {
  width: 230px !important;
}

.label-width-auto {
  @media screen and (max-width: 320px) {
    width: auto !important;
  }
  @media screen and (max-width: 375px) {
    width: 150px !important;
  }
}
button.btnBackground {
  // padding: 11px !important;
  // border-radius: 0;
  background: rgb(255, 255, 255);
  margin: 0 15px 11px 0;
  // margin-left: 15px !important;
  height: 32px;
  width: 32px;
  color: rgb(2, 122, 255);
  padding: 0px !important;
  border-radius: 3px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 10px 0px, rgba(0, 0, 0, 0.26) 0px 2px 5px 0px;
}

@media screen and (max-width: 420px) {
  .memberDateRangeWrapper {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .memberDateRangeWrapper button {
    width: 100% !important;
  }
  .memberDateRangeWrapper input {
    padding: 8px !important;
    background: #fff;
    margin: 0 0px 8px 0;
    width: 100% !important;
  }
  button.btnBackground {
    z-index: 0;
  }
  div.memberDateRangeWrapper:last-child {
    width: 100% !important;
  }
  .btnMemberStyle {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.status-action-header {
  display: flex;
  position: absolute;
  top: 2%;
  right: 13%;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    width: 93px !important;
    height: 30px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    width: 78px !important;
    height: 25px !important;
  }
  width: 80px;
  height: 20px;
}

.status-action-arrow {
  @media screen and (min-width: 630px) and (max-width: 960px) {
    border-top: 15px solid transparent !important;
    border-bottom: 15px solid transparent !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    border-top: 14px solid transparent !important;
    border-bottom: 11px solid transparent !important;
  }
  border-top: 11px solid transparent;
  border-bottom: 9px solid transparent;
  //border-right: 7px solid #feb02f;
}

.status-action-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  color: white;
  //background-color: #feb02f;
  @media screen and (min-width: 630px) and (max-width: 960px) {
    font-size: 14px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 631px) {
    font-size: 12px !important;
  }
  font-size: 9px;
}

.memberDateRangeWrapper input {
  margin-bottom: 8px;
}

.top-section-upload-download {
  width: 100%;
  padding: 5px 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .top-section-upload-download-btn {
    width: 180px;
  }
}

.section-upload-download {
  width: 100%;
  padding: 20px 3%;
  display: flex;
  justify-content: space-between;
}

.card-upload-download-external {
  width: 49%;
  height: auto;
  padding: 3%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 36px #0000000b;
  border-radius: 7px;
  opacity: 1;
  .top-header-btn-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h3 {
      font: normal normal bold 16px/20px Muli;
      letter-spacing: 0.11px;
      color: #000000;
      opacity: 1;
      margin: 0px;
    }
  }

  h3 {
    font: normal normal bold 16px/20px Muli;
    letter-spacing: 0.11px;
    color: #000000;
    opacity: 1;
    margin-bottom: 20px;
    margin-top: 7px;
    margin-left:0px;
  }

  p {
    font: normal normal normal 14px/18px Muli;
    letter-spacing: -0.06px;
    color: #808080;
    opacity: 1;
    margin-bottom: 20px;
  }
  .csvImage {
    border-radius: 6px;
    border: 2px solid #2ecc71;
    width: 100%;
    margin-bottom: 25px;
  }
  ul {
    li {
      margin-bottom: 20px;
      p {
        font: normal normal normal 14px/18px Muli;
        letter-spacing: -0.06px;
        color: #808080;
        opacity: 1;
        margin-bottom: 0px !important;
      }
    }
  }
  h4 {
    font: normal normal bold 14px/20px Muli;
    letter-spacing: 0.11px;
    color: #000000;
    opacity: 1;
    margin-bottom: 20px;
  }
}



.card-upload-download-external-inner-list {
  margin-bottom: 10px !important;
  margin-top: 8px;
  list-style-type: circle;
  .list-item {
    margin-bottom: 5px;
    .inner-paragraph {
      font: normal normal normal 14px/18px Muli;
      letter-spacing: -0.06px;
      color: #808080;
      opacity: 1;
      margin-bottom: 0px !important;
    }
  }
}
.section-upload-download-note {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
}

.download-csv-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div {
    a {
      font-family: Muli, sans-serif !important;
      letter-spacing: 0.1px !important;
      color: #ffffff !important;
      opacity: 1 !important;
    }
  }
}

.recent-history-heading {
  font: normal normal bold 16px/20px Muli;
  letter-spacing: 0.11px;
  color: #000000;
  opacity: 1;
  margin-bottom: 20px;
}

.csv-upload-modal {
 // width: 600px;
  padding: 0 50px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.csv-upload-modal-svg {
  // height: 270px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  img {
    margin-bottom: 5px;
  }
  h3 {
    margin-bottom: 5px;
    font: normal normal bold 16px/18px Muli;
    letter-spacing: 0.12px;
    color: #808080;
    font-weight: bold;
  }
  p {
    font: normal normal normal 14px/16px Muli;
    letter-spacing: 0.1px;
    color: #808080;
    opacity: 1;
  }
}

.csv-upload-modal-button {
  // height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
  align-items: center;
  overflow: hidden;
  h4 {
    margin-top: 10px;
    font: normal normal bold 12px/15px Muli;
    letter-spacing: 0.08px;
    color: #000000;
    opacity: 1;
    font-weight: bold;
  }
}

.overWriteSwitch > span > span > input

.csv-upload-modal-progress-bar {
  margin-top: 10px;
}

.csv-pending-status-error-check {
  color: inherit;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.recent-history-uploaded-by {
  font: normal normal normal 12px/19px Muli;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.recent-history-time {
  font: normal normal 600 12px/19px Muli;
  letter-spacing: 0px;
  color: #757575;
  opacity: 1;
}

.recent-history-status {
  font: normal normal 600 12px/19px Muli;
  letter-spacing: 0px;
  opacity: 1;
}

.recent-history-download {
  font: normal normal 600 12px/19px Muli;
  letter-spacing: 0px;
  color: #027aff;
  opacity: 1;
}

.csvUploadtbl p.showing-records {
  margin-bottom: 0px;
}

.recent-history-table-header {
  font: normal normal normal 13px/16px Muli;
  letter-spacing: 0px;
  color: #757575;
  opacity: 1;
}

.card-body-upload-csv {
  padding: 20px 0px;
  height: 100%;
  border-radius: 5px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .card-upload-download-external {
    height: 820px !important;
    width: 49% !important;
    padding: 3% !important;
    margin-bottom: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 992px) {
  .section-upload-download {
    width: 100%;
    padding: 3%;
    margin-bottom: 30px;
    display: block;
  }
  .card-upload-download-external {
    height: auto !important;
    width: 100% !important;
    padding: 3% !important;
    margin-bottom: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 650px) {
  .card-upload-download-external {
    height: auto !important;
    width: 100% !important;
    padding: 20px !important;
    margin-bottom: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  
}
@media screen and (max-width:599px){
  .csvUploadtbl p {
    margin-top: 25px;
   }
}
@media screen and (max-width: 480px) {
  .top-section-upload-download {
    display: block;
  }
  .csvUploadtbl p {
    margin-top: 25px;
  }
  .card-upload-download-external {
    height: auto !important;
    width: 100% !important;
    padding: 20px !important;
    margin-bottom: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    .top-header-btn-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h3{
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .csvUploadtbl p {
    margin-top: 25px;
  }
  .card-upload-download-external {
    height: auto !important;
    width: 100% !important;
    padding: 20px !important;
    margin-bottom: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    .top-header-btn-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h3{
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .csvUploadtbl p {
    margin-top: 25px;
  }
  .card-upload-download-external {
    height: auto !important;
    width: 100% !important;
    padding: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    margin-bottom: 20px !important;
    .top-header-btn-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h3{
        margin-bottom: 10px;
      }
    }
  }
}
.noMarginLeft{
  margin-left: 0px !important;
  margin-top: 10px !important;
}
.marginTop{
  margin-top: 10px !important;
}
.childDivWidth{
  width: 630px !important;
  flex-flow: wrap !important ;
  height: auto !important;
}
.hideDateInput input {
  display: none;
}
.react-tel-input > .special-label {
  display: none;
}
@media screen and (max-width: 400px){
  .modelBTN {
    flex-direction: column!important;
  }
  .dropDownInfoBtn{
    margin: 12px;
  }
  .cta-tbl td {
    padding: 7px 0 !important;
  }
  .labelInput{
    width: 206px !important;
  }
}

.reset-check-conditions-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.reset-check-conditions-section > ul > li {
  margin-bottom: 2px;
}

.reset-check-list-item {
  display: flex;
  flex-direction: row;
  font-family: "Muli", sans-serif;
  font-size: 11px;
  color: #bfbfbf;
  text-decoration: none;
}

.reset-condition-dot {
  background-color: #bfbfbf;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  margin-top: 6px;
  margin-right: 8px;
}

.green-dot {
  background-color: #02b67c;
}
.topbarTrial{
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #FBFBFB;
  padding: 14px;
  // cursor: pointer;
  justify-content: center;
}
.shade{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
@media screen and (max-width: 599px){
.mobScreen{
  margin-bottom: 288px;
}
}

.reset-check-list-item > span {
  padding-top: 3px;
}
.csv-upload-modal-button {
  label {
    font: normal normal normal 14px/16px Muli;
    letter-spacing: 0.1px;
    color: #808080;
    opacity: 1;
    margin: 0px;
    height: 30px;
    @media screen and (max-width:645px){
      height: auto !important;
    }
  }
  .rdo-btn-merge{
    margin: 20px 0;
  }
}

@media screen and (max-width:450px){
  .btn-input-group-form{
    display: flex;
    flex-direction: column !important;
  }
  .btn-input-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.activeSlide {
  border-radius:unset !important;
  span{
    opacity: 0;
  }
}
.sliderBtn {
  &:hover{
    border-radius:unset !important;
  }
}
.slider-item-wrapper {
  height: 364px;
  width: 100%;
  box-shadow: unset !important;
  @media screen and (min-width: 630px) and (max-width: 960px) {
   // height: 150px !important;
  }
  @media screen and (max-width: 631px) {
    height: 20% !important;
  }
}

.slider-csv-upload {
  @media screen and (max-width: 631px) {
   min-height: 200px;
   .CarouselItem{
     min-height: 150px;
   }
  }

}

.slider-csv-upload .CarouselItem{
  border: 0px !important;
}

.csv-upload-modal{
  @media screen and (max-width: 631px) {
    padding: 0px;
   }
}

.timezone-dropdown-menu {
  font-size: 14px !important;
}

.remove-padding-timezone {
  @media screen and (max-width: 960px) {
    padding-left: 0px !important;
   }
}