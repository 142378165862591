.table-header {
  user-select: none;
  table-layout: auto;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.table-header:hover {
  cursor: pointer;
}

.table-row {
  color: rgb(59, 59, 156);
  background-color: white;
}

.table-row-active {
  color: white;
  background-color: rgb(59, 59, 156);
}

.table-previous-page {
  padding-right: 5px;
}

.table-row,
.table-row-active {
  user-select: none;
  table-layout: auto;
  border: none;
  height: 30px;
  width: 30px;
  text-align: center;
  /* border: 1px solid #dddddd; */
}

.table-row:hover,
.table-row-active:hover {
  cursor: pointer;
}

.table-button {
  pointer-events: none;
}
