.saveBtn {
  height: 35px !important;
  width: 160px !important;
  border-radius: 3px !important;
  background-color: #027AFF !important;
  color: #ffffff !important;
  text-transform: uppercase;
  font-size: 13px !important;
  font-weight: bolder;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

chart {
  display: block;
  width: 100%;
}
