.react-grid-Cell__value {
  display: flex !important;
  align-items: center !important;
  overflow: visible !important;
}

.react-grid-Cell__value div span {
  display: flex;
}

.react-grid-image {
  border-radius: 6px;
  cursor: pointer;
  /* box-shadow: -2px 4px 10px 1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); */
}
 
.detailKeyWidth{
  max-width: 50% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
