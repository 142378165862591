.pm-main-heading {
  color: rgb(35, 32, 31);
  font-size: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  font-weight: bolder;
}

.pm-main-heading-loading {
  color: rgb(35, 32, 31);
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  font-weight: bolder;
}

.pm-main-plan-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pm-main-plan-type {
  color: #027aff;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  text-decoration: none;
  margin: 0 7px;
  cursor: pointer;
}
.pm-main-plan-type-selected {
  color: #027aff;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  text-decoration: underline;
  margin: 0 7px;
  cursor: pointer;
}
.pm-footer {
  color: 252728 rgb(37, 39, 40);
  line-height: 27px;
  font-size: 16px;
  font-weight: 300;
  padding: 0em 3em 3em 3em;
}
.pm-footer-highlight {
  color: #027aff;
  cursor: pointer;
  > a {
    text-decoration: none;
  }
}
.pm-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0 3em 3em 3em;
}

.pm-cp-column {
  width: 280px;
  height: auto;
  min-height: 898px;
  margin-top: 3em;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pm-cp-column-upper-column {
}

.pm-container-inner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pm-cp-silver {
  background-color: rgb(192, 192, 192);
  border-color: rgba(144, 164, 183, 0.22);
  border-radius: 6px;
  box-shadow: 2px 2px rgba(144, 164, 183, 0.22);
}

.pm-cp-heading {
  color: rgb(205, 127, 50);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.pm-cp-heading-silver {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.pm-cp-cost {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: rgb(37, 39, 40);
  font-size: 42px;
  font-weight: 500;
  margin: 0px;
}

.pm-cp-cost-silver {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: rgb(255, 255, 255);
  font-size: 42px;
  font-weight: 500;
  margin: 0px;
}

.pm-cp-cost {
  color: inherit;
  display: flex;
  align-items: flex-start;
}

.pm-cp-currency {
  font-size: 26px;
}

.pm-cp-plan-description {
  font-size: 12px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-align: center;
  height: 50px;
}

.pm-cp-plan-description-silver {
  font-size: 12px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-align: center;
  height: 50px;
}

.pm-cp-features {
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.pm-cp-features-item {
  border-bottom: 2px solid rgb(153, 153, 153);
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em !important;
  margin: 0px !important;

  > img {
    margin-right: 15px;
  }

  @media (max-width: 440px) {
    height: 120px;
  }
}

.pm-cp-features-item-description {
  color: rgb(153, 153, 153);
  font-size: 14px;
}

.pm-cp-features-item-silver {
  border-bottom: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em !important;
  margin: 0px !important;

  > img {
    margin-right: 15px !important;
  }
  > svg {
    margin-right: 15px !important;
  }

  @media (max-width: 440px) {
    height: 120px;
  }
}

.pm-cp-no-border {
  border-bottom: none;
}

.pm-cp-downgrade-btn {
  height: 40px;
  width: 100%;
  margin-top: 10px !important;
  background-color: #ff1e59 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.pm-cp-add-payment-btn {
  height: 40px;
  width: 100%;
  margin-top: 10px !important;
  background-color: rgb(2, 122, 255) !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.pm-cp-btn-disabled {
  height: 40px;
  width: 100%;
  margin-top: 10px !important;
  background-color: #000 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #efefef !important;
  font-weight: 400 !important;
}

.pm-cp-btn-spacer {
  margin-right: 10px;
}

.pm-cp-current-btn {
  height: 40px;
  width: 100%;
  margin-top: 10px !important;
  background-color: rgb(192, 192, 192) !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: 1px solid rgb(255, 255, 255) !important;
}

.pm-cp-upgrade-btn {
  height: 40px;
  width: 100%;
  margin-top: 10px !important;
  background-color: rgb(2, 182, 124) !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.pi-card-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pi-card-container-internal {
  display: flex;
  flex-direction: row;
  align-items: center;
  > img {
    width: 25px;
    height: 25px;
  }
  > span {
    margin-right: 10px;
  }
}

.pi-card-settings-icon {
  margin-right: 0px !important;
}

.pi-default-card {
  width: 66px;
  height: 22px;
  padding: 1px 10px;
  color: #fff;
  background-color: grey;
  border-radius: 20px;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0) 0px 6px 7px -4px,
    rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.18) 0px 4px 20px 3px !important;
}

.pm-modal-container {
  padding: 3em !important;
}

.pm-heading {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.pm-heading-green {
  color: rgb(2, 182, 124);
}

.pm-heading-red {
  color: #ff1e59 !important;
}

.pm-description {
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 27px;
  font-weight: 400px;
  margin-top: 20px;
}

.pm-button-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  > button {
    width: 200px !important;
    text-transform: uppercase !important;
  }
  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    > button {
      width: 200px !important;
      text-transform: uppercase !important;
      margin-bottom: 20px;
    }
}
}

.pm-single-button-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  > button {
    width: 200px;
    text-transform: uppercase !important;
  }
}

.add-card-form {
  min-width: 300px;
  height: auto;
}

.pm-custom-main {
  width: inherit;
  margin-top: 3em;
  p {
    font-size: 14px;
    margin-bottom: 1.5em;
    text-align: justify;
  }
}

.pm-custom-plan-card {
  padding: 15px 20px;
  box-shadow: 0 6px 7px -4px transparent, 0 11px 15px 1px transparent,
    0 4px 20px 3px rgba(0, 0, 0, 0.12) !important;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.pm-custom-plan-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 540px) {
    flex-direction: column;
    align-items: center;
  }
}

.pm-custom-plan-heading {
  font-size: 30px;
  color: rgb(205, 127, 50);
  @media (max-width: 540px) {
    text-align: center;
    font-size: 24px;
  }
}

.pm-custom-plan-cost-section {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  @media (max-width: 540px) {
    padding: 0 !important;
    text-align: center;
  }
}

.pm-custom-currency {
  font-size: 26px;
}
.pm-custom-plan-cost-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .cost {
    height: 44px;
    font-size: 34px;
    font-weight: 500;
    @media (max-width: 540px) {
      height: 40px;
      font-size: 30px;
      font-weight: 500;
    }
  }
  > .occurance {
    font-size: 12px;
    font-weight: 500;
  }
}

.pm-custom-plan-features {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  @media (max-width: 540px) {
    padding: 0 !important;
  }
}

.pm-custom-plan-list-item {
  list-style-type: none;
}

.pm-custom-plan-list-item {
  display: flex;
  align-items: center;
  width: 350px;
  margin: 12px 0;
  text-align: left;
  > span {
    margin-left: 15px;
  }
  > img {
    @media (max-width: 540px) {
      padding-top: 3px;
    }
  }
  @media (max-width: 540px) {
    align-items: flex-start;
  }
}

.pm-custom-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    margin-top: 20px;
    line-height: 27px;
    font-size: 14px;
    font-weight: 300;
    padding: 0 3em 1em;
    @media (max-width: 540px) {
      text-align: justify;
    }
  }
  > button {
    // margin-top: 25px;
    width: 180px;
    margin-bottom: 2.5em;
  }
}

.cod-menuItem {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16 !important;
}

.cod-black {
  color: rgb(0, 0, 0) !important;
}

.cod-red {
  color: rgb(255, 48, 48) !important;
}

.pm-custom-plan-container {
  padding: 10px 70px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.text-align-center {
  text-align: center !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.pm-custom-footer-payment-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  > p {
    margin-top: 20px;
    line-height: 27px;
    font-size: 14px;
    font-weight: 300;
    padding: 0 3em 1em;
    text-align: center;
    @media (max-width: 540px) {
      text-align: justify;
    }
  }
  > button {
    // margin-top: 25px;
    width: 180px;
    margin-bottom: 2.5em;
  }
}