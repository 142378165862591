
.associates-table{
    padding: 10px;
}

.table-pagination{
    /* height: 100px; */
    user-select: none;
    padding: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    bottom: 0;
}

.font-in-bold{
    font-weight: bold;
}

.justify-center{
    align-self: center;
}