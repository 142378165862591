.payment-history-heading-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.payment-history-view-all {
  color: rgb(2, 122, 255);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.75;
  animation-delay: 1s;
  &:hover {
    font-weight: 700;
  }
}

.payment-history-single {
  padding: 10px 0;
  font-size: 14px;
  @media (max-width: 320px) {
    font-size: 12px;
  }
}
