.onBoard-page-container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 70px;
}

.onBoard-page-container-top {
  height: auto;
  // overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onBoard-page-video-container {
  margin-bottom: 30px;
  @media screen and (max-width: 599px) {
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: column-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
}

.onBoard-page-container-bottom {
  height: 60px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
  z-index: 1111;
  box-shadow: rgba(0, 0, 0, 0) 0px 6px 7px -4px,
    rgba(0, 0, 0, 0) 0px 11px 15px 1px, rgba(0, 0, 0, 0.12) 0px 4px 20px 3px !important;
}

.onboard-new-outer-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 85%;
  margin-top: 100px;
  @media screen and (max-width: 706px) {
    width: 100%;
  }
  @media screen and (min-width: 800px) and (max-width: 1024px) {
    width: 95%;
  }
  // @media screen and (min-width: 706px) and (max-width: 800px) {
  //   width: 80%;
  // }
}

.onBoard-page-container-welcome {
  width: 50%;
  margin-top: 100px;
  @media screen and (max-width: 706px) {
    width: 100%;
  }
  @media screen and (min-width: 800px) and (max-width: 1024px) {
    width: 70%;
  }
  @media screen and (min-width: 706px) and (max-width: 800px) {
    width: 80%;
  }
}

.onBoard-page-container-welcome-scan-details {
  width: 70%;
  margin-top: 100px;
  @media screen and (max-width: 410px) {
    width: 70%;
  }
  @media screen and (max-width: 959px) {
    // margin-top: 1000px;
  }
}

.onBoard-page-container-welcome-mobile-videos {
  width: 70%;
  margin-top: 150px;
  @media screen and (max-width: 410px) {
    width: 70%;
  }
  @media screen and (max-width: 959px) {
    // margin-top: 1000px;
  }
}

.onBoard-page-container-welcome-input-gap-left {
  padding-right: 12px !important;
  @media screen and (max-width: 600px) {
    padding-right: 0 !important;
  }
}

.onBoard-page-container-welcome-input-gap-right {
  padding-left: 12px !important;
  @media screen and (max-width: 600px) {
    padding-left: 0 !important;
  }
}

.onBoard-page-btn {
  height: 40px;
  width: 200px;
  background-color: #ff1e59 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
  // margin-top: 60px !important;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.onboard-page-qr {
}

.qr-code-container-responsive {
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
}

.qrcode-container {
  width: 200px;
  padding: 10px;
  background-color: white;
  border: 4px solid black;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 150px;
    margin-left: 2rem;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
  @media (max-width: 600px) {
    margin-top: 2rem;
  }
}

.onboard-video-sec-new {
  @media (max-width: 600px) {
    margin-top: 0;
  }
}

.onBoard-page-btn-video-section {
  height: 40px;
  width: 200px;
  background-color: #ff1e59 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
  margin-top: 20px !important;
  // margin-top: 60px !important;
  @media screen and (max-width: 600px) {
    margin-top: 20px !important;
    width: 100%;
  }
}

.onBoard-page-btn-disabled {
  height: 40px;
  width: 200px;
  background-color: #757575 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-weight: 400 !important;
  // margin-top: 60px !important;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.onboarding-welcome-title {
  color: #23201f !important;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 27px;
}

.onboarding-new-text-description {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}

.onboarding-text-heading {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 25px;
}

.onboarding-text-subText {
  color: #000000 !important;
  font-size: 14px;
  font-weight: 700;
  // margin-bottom: 25px;
}

.onboard-account-ready-hint {
  margin-left: 10px;
}

.onboarding-new-text-bullet {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 500;
  margin: 27px 0;
}

.onboarding-welcome-description {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 27px;
}

.onboarding-add-rec-description {
  color: #000000 !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 27px;
}

.success {
  color: rgb(46, 204, 113);
}

.error {
  color: rgb(255, 48, 48);
}

.left-input-onboarding {
  padding-right: 10px !important;
  @media screen and (max-width: 599px) {
    padding-right: 0px !important;
  }
}

.right-input-onboarding {
  padding-left: 10px !important;
  @media screen and (max-width: 599px) {
    padding-left: 0px !important;
  }
}

.container-onboarding {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px 20px;
  margin-bottom: 20px;
}
.container-onboarding-recipient-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px !important;
}
.container-onboarding-recipient-btn {
  width: 27px;
  height: 27px;
  padding-top: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff1e59;
  border-radius: 15px;
  color: #ff1e59;
  cursor: pointer;
}
.container-onboarding-recipient-btn > svg {
  font-size: 17px;
}
.container-onboarding-recipient-btn:hover {
  border: 2px solid #ff1e59;
}
.container-onboarding-add-new-recipient {
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  color: rgb(115, 122, 146) !important;
  cursor: pointer;
}
.container-onboarding-add-new-recipient:hover {
  color: #ff1e59 !important;
}
.container-onboarding-add-recipient-btn {
  width: 27px;
  height: 27px;
  padding-top: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(115, 122, 146);
  border-radius: 15px;
  color: rgb(115, 122, 146);
  font-size: 12px !important;
  margin-right: 5px;
}
.container-onboarding-add-recipient-icon {
  font-size: 17px !important;
  // border: 1px solid rgb(115, 122, 146);
}
.container-onboarding-add-recipient-icon-hover {
  // border: 1px solid #ff1e59;
  font-size: 17px !important;
}

.container-onboarding-add-recipient-btn-hover {
  width: 27px;
  height: 27px;
  padding-top: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff1e59;
  border-radius: 15px;
  color: #ff1e59;
  font-size: 12px !important;
  margin-right: 5px;
}

.onboarding-welcome-notification-section {
  margin-bottom: 30px !important;
}

.onboarding-welcome-notification-list-item {
  margin-bottom: 10px;
}

.onboard-dropdown-next-input {
  padding-top: 3px;
}

.onboarding-scan-label-image {
  height: 600px;
  width: 100%;
  @media (min-width: 1366px) {
    width: auto;
  }
}

.onboarding-scan-label-images-box {
  // border: 1px solid black;
}

.onboarding-scan-label-image-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.onboarding-scan-label-image-user-name {
  position: absolute;
  top: 49%;
  left: 26%;
  font-weight: 900;
  font-family: sans-serif !important;

  @media (min-width: 1920px) {
    font-size: 1rem;
    left: 31%;
    top: 47%;
  }
  @media (min-width: 2200px) {
    font-size: 1.2rem;
    left: 31%;
    top: 47%;
  }
  @media (min-width: 3000px) {
    font-size: 1.4rem;
    left: 32%;
    top: 47%;
  }
  @media (max-width: 1366px) {
    left: 21%;
  }
  @media (max-width: 992px) {
    left: 18%;
  }
  @media (max-width: 768px) {
    left: 17%;
  }
}

video {
  pointer-events: none;
  position: absolute;
  left: 70px !important;
  width: 290px;
  height: 592px;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

.video-player {
  // height: auto;
  // width: 100%;
  // top: 0px;
  top: -40px !important;
  left: 0px !important;
  height: auto;
  border-radius: 20px;
  box-shadow: -5px 5px 10px #444;
  width: 160px;
  @media screen and (max-width: 599px) {
    left: 26px !important;
    top: 0px !important;
    height: auto;
    width: 225px;
    border-radius: 25px;
  }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    top: -40px !important;
    left: 0px !important;
    height: auto;
    width: 160px;
  }
}

.video-section-onboarding-new {
  position: relative;
  height: 450px;
  width: auto;
  border-radius: 20px;
  // background: black;
  // margin: 10px;
  // border: 2px solid #000;
  // border: 3px solid #fafbfe;
  // outline: none;
}
.video-player-new {
  top: 0 !important;
  left: 0 !important;
  width: auto;
  height: inherit;
  border-radius: 35px;
  border: none;
  outline: none;
  // border: 3px solid black;
  box-shadow: -5px 5px 10px #fafbfe;
}

.video-section {
  position: relative;
  @media screen and (min-width: 501px) and (max-width: 599px) {
    top: 450px;
    left: 0px;
  }
  @media screen and (min-width: 420px) and (max-width: 500px) {
    top: 520px;
    left: 0px;
  }
  @media screen and (min-width: 320px) and (max-width: 419px) {
    top: 580px;
    left: -30px;
  }
}

.video-gutter {
  padding-right: 30px;
  @media screen and (max-width: 600px) {
    margin-top: 30px !important;
  }
}

.video-wrapper {
  // position: absolute;
  // width: 373px;
  // height: 640px;
  // left: 27px;
  // top: -24px;
  // z-index: 111111;
  position: absolute;
  width: 200px;
  height: 350px;
  left: -20px;
  top: -14px;
  z-index: 111;
  @media screen and (min-width: 601px) and (max-width: 900px) {
    position: absolute;
    width: 200px;
    height: 350px;
    left: -20px;
    top: -14px;
    z-index: 111;
  }
  @media screen and (max-width: 599px) {
    position: absolute;
    width: 280px;
    height: 480px;
    left: 0px;
    top: -18px;
    z-index: 111;
  }
}

.video-description-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.pickup-packages-form {
  width: 85%;
  @media (max-width: 480px) {
    width: 100%;
  }
}

.skip-tutorial {
  font-family: Muli, sans-serif !important;
  cursor: pointer;
  color: rgb(2, 122, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  .spacer {
    width: 16px;
  }
  > svg {
    display: none;
  }
  &:hover {
    text-decoration: underline;
    > svg {
      display: inline;
      margin-left: -4px;
    }
    .spacer {
      display: none;
    }
  }
}

.onboard-new-pl-15 {
  padding-left: 15px;
}

.onboard-new-mb-10 {
  margin-bottom: 10px;
}
.onboard-new-mb-15 {
  margin-bottom: 15px;
}
.onboard-new-mb-60 {
  margin-bottom: 60px !important;
}
.onboard-new-mb-40 {
  margin-bottom: 40px !important;
}
.onboard-new-mt-60 {
  margin-top: 60px !important;
}

.onboard-new-instructions-list {
  > li {
    margin-bottom: 20px;
  }
}

.onboard-new-end-para-center {
  color: #212529 !important;
  // text-align: center;
  margin-bottom: 30px;
  > p {
    color: #212529 !important;
  }
}

.onboard-new-end-para-left {
  color: #212529 !important;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 1rem;
  > p {
    color: #212529 !important;
  }
}

.onboard-new-instructions-list-inner {
  margin-top: 10px;
  > li {
    margin-bottom: 10px;
    margin-left: 15px;
  }
}
.onboard-new-icon {
  width: 48px;
  height: auto;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 3px;
}

.onboard-new-width-35px {
  width: 35px !important;
}
.onboard-new-width-30px {
  width: 30px !important;
}
.onboard-new-width-20px {
  width: 20px !important;
}
.onboard-new-width-95px {
  width: 95px !important;
}
.onboard-new-width-68px {
  width: 68px !important;
}

.onboard-new-scan-item-image {
  height: 450px;
  width: 100%;
  @media (min-width: 1366px) {
    width: auto;
  }
}

.onboard-new-scan-item-image-user-name {
  position: absolute;
  top: 41%;
  left: 43%;
  font-weight: 900;
  font-family: sans-serif !important;

  @media (max-width: 1440px) {
    font-size: 0.8rem;
    left: 40%;
    top: 41%;
  }
  @media (max-width: 1200px) {
    font-size: 0.8rem;
    left: 38%;
    top: 37%;
  }
  @media (max-width: 1024px) {
    top: 35%;
    left: 37%;
  }
  @media (max-width: 768px) {
    left: 31%;
    top: 28%;
  }
  @media (max-width: 600px) {
    left: 39%;
    top: 41%;
  }
  @media (max-width: 426px) {
    left: 35%;
    top: 41%;
  }
  @media (max-width: 376px) {
    left: 32%;
    top: 41%;
  }
  @media (max-width: 340px) {
    left: 31%;
    top: 42%;
  }
}
